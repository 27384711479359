<template>
  <div class="mx-5">
    <b-row class="m-0 my-1" style="align-items:center;" align-h="between" >
      <b-col cols="4" style="display:flex; align-items:center;">
        <b-link class="brand-logo">
            <b-img
              v-if="gettingA"
              :src="logoAzienda ? logoUrl : appLogoImage"
              alt="logo"
              style="height:25px;"
            />
        </b-link>
        <!-- <b-card-title
          class="m-0 ml-1"
        >
         benvenuto su WB OstiSistemi
        </b-card-title> -->
      </b-col>
      <b-col cols="2" class="p-0">
        <dark-Toggler style="width:100%;" class="d-lg-block" />
      </b-col>
    </b-row>

  <!-- CREATE or Update -->
    <div v-if="segnalazione">
      <!-- <segnalazione-form/> -->
      <b-row align-h="between" class="p-1">
        <div style="border-left:2px solid red;" class="p-1 mb-1">
          <p class="card-text text-primary h5">
            Come accedere alla segnalazione
          </p>
          <p class="card-text text-secondary">
            Per accedere alla segnalazione ti verrà richiesto l'
            <b>ID</b> (che trovi qui di seguito), ed eventualmente la
            <b>password</b> (se hai deciso di impostarne una), per
            <i>garantire la tua privacy</i>
            non ti sarà inviata nessuna notifica (email, sms, push), quindi
            <b>conservare questi dati in un luogo sicuro</b>
            altrimenti non potrai più accedere alla segnalazione e non potrai consultare eventuali risposte.
          </p>
        </div>
        <b-input-group>
           <b-form-input :value="segnUuid" />
           <b-input-group-append>
             <b-button @click="copyLink()" variant="outline-primary">
              Copia
             </b-button>
             <b-button @click="downloadTextFile()" variant="outline-primary">
              <feather-icon icon="DownloadIcon" />
             </b-button>
           </b-input-group-append>
        </b-input-group>
      </b-row>
      <b-card class="text-center">
        <b-card-title class="mx-1">
          <b-row class="mb-1" align-h="between">
            <b-button
              class="mx-1"
              text="mx-1"
              variant="outline-warning"
              @click="$router.push({ name: 'submitReport', params: { token: $route.params.token }})"
            >
             Indietro
            </b-button>
            <b-button
              v-if="!segnalazione.closedAt"
              class="mx-1"
              variant="danger"
              v-b-modal.close-segn
            >
              Chiudi Segnalazione
            </b-button>
            <b-card-title v-else class="mx-1 text-danger">
              <b-card-text class="text-left">
                Chiusa il:
              </b-card-text>
              {{new Date(segnalazione.closedAt).toLocaleString('it-IT', dateOptions)}}
            </b-card-title>
          </b-row>
          <b-row class="p-1">
            {{segnalazione.title}}
          </b-row>
          <b-row class="p-1">
            <b-card-text class="text-muted h6 mr-2">
              <feather-icon icon="CalendarIcon" />
              Inserita il:
            </b-card-text>
            {{new Date(segnalazione.createdAt).toLocaleString('it-IT', dateOptions)}}
          </b-row>
          <b-row v-if="segnalazione.name || segnalazione.surname" class="p-1">
            <b-card-text class="text-muted mr-2">
              <feather-icon icon="UserIcon" />
              Nome:
            </b-card-text>
            {{segnalazione.name}}
            {{segnalazione.surname}}
          </b-row>
          <b-row v-else class="p-1">
            <b-card-text class="text-muted mr-2">
              <feather-icon icon="UserIcon" />
              Utente: <strong>anonimo</strong>
            </b-card-text>
          </b-row>
          <b-row v-if="segnalazione.email" class="p-1">
            <b-card-text class="text-muted mr-2">
              <feather-icon icon="MailIcon" />
              email:
            </b-card-text>
            {{segnalazione.email}}
          </b-row>
          <b-row v-if="segnalazione.awaitAnswer" class="p-1">
            <b-card-text class="text-muted mr-2">
              Hai richiesto di ricevere risposta
            </b-card-text>
          </b-row>
        </b-card-title>
      </b-card>
<!--
    Chat - inverted cronologic order
 -->
      <b-card
        :bg-variant= "diff ? 'light' : 'default'"
      >
        <b-card-text class="text-left h6 text-muted">dalla più alla meno recente.</b-card-text>
        <app-timeline>
          <app-timeline-item
            v-for="item in reverseContenutos"
            :key="item.uuid"
            :subtitle="item.body"
            :time="`${new Date(item.createdAt).toLocaleString('it-IT', dateOptions)}`"
            :icon="item.userUuid ? 'UserIcon' : 'ShieldIcon'"
            :variant="item.userUuid ? 'success' : 'warning'"
            :userFullname="item.userUuid ? item.user.userDetail.name+' '+item.user.userDetail.surname : 'anon'"
            :file="item.file"
          />
          <!-- :userFullname="item.userUuid ? item.user.userDetail.name+' '+item.user.userDetail.surname : 'anon'" -->
        </app-timeline>
        <!-- <b-card-text class="text-right font-weight-bolder h6 text-success">meno recente</b-card-text> -->
      </b-card>
<!--
    Answer
 -->
      <b-card
        title="Rispondi"
      >
        <!-- <b-input-group>
           <b-form-input v-model="title" placeholder="Titolo" />
        </b-input-group> -->
        <b-input-group class="mt-1">
           <b-form-textarea v-model="body" rows="7" placeholder="Risposta" />
        </b-input-group>
        <div class="w-25">
          <b-input-group class="mt-1">
            <b-form-file
              class="form-control"
              v-model="upload_file"
              :state="upload_file ? Boolean(upload_file) : null"
              placeholder="Scegli un file da allegare..."
              drop-placeholder="Trascina qui il file..."
              browse-text="Allega"
            />
          </b-input-group>
          <!-- <div v-if="upload_file" class="text-success">File: {{ upload_file ? upload_file.name : '' }}</div> -->
        </div>
        <b-row align-h="end">
          <b-button
            class="m-1"
            :variant="`outline-${isDark ? 'warning' : 'primary'}`"
            @click="warnErrorsHints()"
          >
            Invia
          </b-button>
        </b-row>
      <!-- </b-card> -->
      </b-card>
    </div>

    <b-modal
      id="close-segn"
      title="Chiudi segnalazione"
      ok-title="Conferma chiusura"
      cancel-title="Annulla chiusura"
      @ok="closeSegnalazione()"
    >
     <p class="my-4">
       Dopo la conferma non sarà più possibile rispondere.
     </p>
    </b-modal>

    <footer
      class="footer footer-light"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
import {
  BImg, BRow, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormFile, BInputGroup, BInputGroupAppend,
  BCard, BCardText, BCardTitle, BLink, BButton, BFormTextarea, BFormRadio
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { $themeConfig } from '@themeConfig'
// import SegnalazioneForm from './Segnalazione.vue'


export default {
  data() {
    return {
      appLogoImage: $themeConfig.app.appLogoImage,
      darkLogoImg: $themeConfig.app.appLogoImage,
      azienda: null,
      logoAzienda: null,
      apiLocation: null,
      logoUrl: null,
      gettingA: false,
      segnalazione: null,
      password: null,
      id: null,
      body: '',
      name: null,
      surname: null,
      email: null,
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      diff: false,
      revealIdentity: false,
      awaitAnswer: false,
      upload_file: null,
    }
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { isDark }
  },
  mounted() {
    this.getAzienda()
    this.getSegnalazione()
  },
  computed: {
    reverseContenutos() {
      return this.segnalazione && this.segnalazione.contenutos ? this.segnalazione.contenutos.reverse() : []
    },
    currentRouteName() {
      return this.$route.name;
    },
    azToken() {
      return this.$route.params.token;
    },
    segnUuid() {
      return this.$route.params.uuid;
    },
    segnPwd() {
      return this.$route.params.password && this.$route.params.password.length ? this.$route.params.password : null;
    },
    postUrl() {
      return '/update';
    },
  },
  methods: {
    getAzienda()
    {
      this.gettingA = false
      this.$http.get("/segnalazione/getAzienda/"+this.$route.params.token).then( res=>{
      this.azienda = res.data.az
        if (this.azienda.logoFile.startsWith("public/")) {
          this.logoAzienda = this.azienda.logoFile.slice(7);
        } else {
          this.logoAzienda = this.azienda.logoFile;
        }
        this.apiLocation = process.env.VUE_APP_BACKEND_API+'/'
        this.logoUrl = this.apiLocation+this.logoAzienda
        this.gettingA = true
    })
    },
    warnErrorsHints() {
      if (this.upload_file && this.upload_file.size > 2 * 1024 * 1024) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File troppo grande',
            text: 'Inserire un file 2MB max',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        if (!this.segnalazione && !this.password) {
            this.$bvModal.show('pwd-warning');
        } else {
          this.submitSegnalazione()
        }
      }
    },
    buildSaveObject() {
      let obj = {
        uuid: this.segnUuid,
        password: this.password,
        // awaitAnswer: this.awaitAnswer,
        contenutos: {
          body: this.body,
          segnalazioneUuid: this.segnalazione.uuid,
        }
      }
      return obj
    },
    buildSaveObject_new() {
      const formData = new FormData();
      formData.append('uuid', this.segnUuid);
      formData.append('password', this.password);
      // formData.append('awaitAnswer', this.awaitAnswer);
      // formData.append('title', this.title);
      formData.append('contenutos[body]', this.body);
      formData.append('contenutos[segnalazioneUuid]', this.segnalazione.uuid);

      if (this.upload_file) {
        formData.append('contenutos[upload_file]', this.upload_file, this.upload_file.name);
      }
      return formData;
    },
    submitSegnalazione() {
      if (this.body && this.body.length > 9) {
        this.$http.post('/segnalazione/'+this.azToken+this.postUrl,
          this.buildSaveObject_new()
        ).then(res => {
          // console.log('Segnalazione ',res.data.segn)
          // this.segnalazione = res.data.segn
          if (res && res.status == 200) {
            this.upload_file = null
            this.titolo = ''
            this.body = ''
            this.password = null
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Segnalazione',
                text: 'Risposta inserita nel sistema',
                icon: 'SlackIcon',
                variant: 'success',
              },
            })
            if (res.data && res.data.segn) {
              this.segnalazione = res.data.segn
            }
            this.diff = true;
            setInterval(() => {
              this.diff = false;
            }, 1800);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password non corretta',
                icon: 'AlertTriangleIcon',
                variant: 'error',
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Messaggio non completo',
            text: 'Inserire contenuto di almeno 10 caratteri',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    getSegnalazione() {
      this.$http.post('/segnalazione/findOne/'+this.$route.params.uuid,
        { password: this.segnPwd },
			).then(res => {
        console.log('Segnalazione ',res.data)
        if (res && res.data.id) {
          this.segnalazione = res.data
          this.titolo = ''
          this.body = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Segnalazione trovata',
              icon: 'SlackIcon',
              variant: 'success',
            },
          })
        } else {
          this.$router.push({ name: 'submitReport'})
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Errore',
              text: 'Verificare i valori inseriti',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
        this.password = null
      }).catch( e => {
        console.log(e);
      })
    },
    closeSegnalazione() {
      this.$http.post('/segnalazione/'+this.azToken+'/close',
        { uuid: this.segnalazione.uuid }
      ).then(res => {
        // this.segnalazione = res.data.segn
        if (res && res.status == 200) {
          this.titolo = ''
          this.body = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Segnalazione chiusa',
              icon: 'SlackIcon',
              variant: 'success',
            },
          })
          // this.resetField()
          this.$router.push({ name: 'submitReport', params: { token: this.azToken }})
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Errore',
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })
        }
      })
    },
    copyLink() {
      navigator.clipboard.writeText(this.segnUuid);
      // return true
    },
    resetField() {
      this.upload_file = null;
      this.revealIdentity = false;
      this.awaitAnswer = false;
      this.segnalazione = null;
      this.password = null;
      this.id = null;
      this.name = null;
      this.surname = null;
      this.email = null;
      this.body = '';
    },
    downloadTextFile() {
      let text = `Identificativo segnalazione: ${this.segnUuid}\nPassword (può essere vuota): ${this.segnPwd ? this.segnPwd : ''}`;

      const blob = new Blob([text], { type: "text/plain" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "WB-OstiSistemi.txt";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BCardText,
    BCardTitle,
    BLink,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    BCardActions,
    BImg,
    ToastificationContent,
    DarkToggler,
    BFormRadio,
    AppFooter,
  },
}
</script>

<style>

</style>
